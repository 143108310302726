<template>
  <main class="about">
    <div class="container">
      <heading-1>Om Skinstudio</heading-1>
      <p class="mb-4">
        Hos SkinStudio har vi flere års erfaring med permanent hårfjerning med
        laser til både kvinder og mænd. Permanent hårfjerning med laser er en
        effektiv og næsten smertefri måde at fjerne uønsket hårvækst over hele
        kroppen. Vi er alle specialuddannede til at udføre hårfjerning med
        laser.
      </p>
      <p class="mb-24">
        Sara er specialuddannet i laserbehandlinger og konsultationer ved
        Skinstudio siden Marts 2020, og er registreret som hjælper under vores
        læge Lina i Styrelsen for Patientsikkerhed. Sara varetager de fleste
        konsultationer af nye kunder i klinikken og derudover har hun også en
        fast kundebase hun tager sig af. Hos Sara er man sikret en høj faglighed
        samt svar på nærmest alle de spørgsmål man kan have om laserbehandling
        med vores laser. Derudover er hun altid frisk på en snak om lidt af
        hvert og hun sørger for at du er tilpas og tryg under dine behandlinger.
        Sara arbejder deltid i klinikken og derfor kan man opleve at der kan
        være begrænset med ledige tider, da de fleste tider er booket 1-1,5
        måned ud i fremtiden.
      </p>
      <heading-2>Vores team</heading-2>
      <ul class="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-4 sm:gap-8">
        <li
          v-for="person in $store.getters.getData.staff"
          :key="person.id"
          class="relative"
        >
          <img
            :src="
              person.picture
                ? '/assets/' +
                  person.picture +
                  '?fit=cover&width=320&height=400'
                : ''
            "
          />
          <div class="mt-5">
            <p class="mb-3 font-semibold leading-4 text-lg text-black">
              {{ person.name }}
            </p>
            <p class="mb-0 text-xs sm:text-sm leading-4">
              {{ person.position }}
            </p>
          </div>
        </li>
      </ul>
    </div>
  </main>
</template>
<script>
import Heading1 from "../components/Heading1.vue";
import Heading2 from "../components/Heading2.vue";

export default {
  name: "About us",
  components: {
    Heading1,
    Heading2,
  },
};
</script>
